import React from 'react'
import YouTube from '@u-wave/react-youtube'

// https://reactjsexample.com/youtube-player-component-for-react/
const YouTubeVidePlayer = props => {
  const { moduleSettings, block } = props
  const { id, muted } = moduleSettings
  const muteVideo = muted === 'on' ? true : false

  const width = '100%'
  const height = block.css.height

  return (
    <div key={id}>
      <YouTube
        video={id}
        muted={muteVideo}
        autoplay
        width={width}
        height={height}
      />
    </div>
  )
}

export default YouTubeVidePlayer
