import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { parseJsonData } from '../../helper/common'
import { createTittleStyle } from '../../helper/module/styleMaker'
import { getMainStyle } from './settings/mainStyle'

/**
 *
 * @param props
 * @returns
 */
const Images = props => {
  const { moduleSettings, block } = props
  const { googleFontLink, parameters } = moduleSettings
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplaySpeed: Number(parameters.scroll_speed_number.stringValue) * 1000
  }

  // added left / right padding
  let cssStyle = Object.assign({}, moduleSettings.moduleCss)
  cssStyle.padding = '0'
  let key = 0
  const jsonData = parameters?.json_data?.stringValue
  const images = jsonData ? parseJsonData(jsonData) : false
  let divs
  const mainTitle = {
    text: parameters?.title_1?.stringValue,
    style: createTittleStyle('title1', parameters)
  }
  const mainStyle = getMainStyle(parameters)
  const imageStyle = {
    width: block?.css?.width
  }
  const imageTextStyle = createTittleStyle('text', parameters)
  if (images) {
    for (const image in images) {
      divs = images[image].map(item => {
        const src = item.path
        const text = item.text
        return (
          <div key={(key = key + 1)}>
            {mainTitle && mainTitle.text && (
              <div style={mainTitle.style}>{mainTitle.text}</div>
            )}
            <img src={src} style={imageStyle} alt={text} />
            {text && <div style={imageTextStyle}>{text}</div>}
          </div>
        )
      })
    }
  }

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={mainStyle}>
        <SliderModule settings={settings} content={divs} />
      </div>
    </HelmetProvider>
  )
}
export default Images
