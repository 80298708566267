/* eslint-disable react-hooks/exhaustive-deps */
// Theme
import { ThemeProvider } from 'styled-components'
import { defaultTheme, GlobalStyles } from './style/theme/landscape'

// Routes
import { AppRoutes } from './routes'

// ErrorBoundary
import ErrorBoundary from './layouts/ErrorBoundary'

// Sentry init
import './utils/sentry'

function App () {
  const appEnv = process.env.REACT_APP_ENV
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
