import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '0'
}

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    activeModules (state, action) {
      state.value = action.payload
    }
  }
})

export const { activeModules } = blocksSlice.actions

export const selectBlocks = state => state.blocks.value

export default blocksSlice.reducer
