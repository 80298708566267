import * as Sentry from '@sentry/react'
import axios from 'axios'
/**
 *
 * @param props
 * @returns
 */
const getMatches = async futbalnetId => {
  const round = 22
  const url =
    process.env.REACT_APP_API_URL +
    `/modules/futbalnet/getMatches?id=${futbalnetId}&round=${round}`
  const result = await axios({
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (res.data.status === 'FAIL') {
        return false
      }
      return res.data.data
    })
    .catch(err => {
      Sentry.captureException(err)
      console.log(err)
      return false
    })
  if (result) {
    let i = 0
    let returnData = `<style>
    .mainClass {
      padding:5px
    }
    .header {
      font-size:40px;
      color:#fff;
      text-align:left;
      margin-bottom:20px
    }
    .imageClass {
      width:50px;
      height:50px;
      background:#fff;
      padding:5px
    } 
    .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
    .column {
      flex: 40%;
      color:#000;
      font-size:20px; 
      display: flex;
      align-items: center; 
      margin:10px 0
    }
    .result{
      flex:10%;
      justify-content:center;
    }
    .left{
      justify-content:right;
    }
    .right{
      justify-content:left;
    }
    .date{
      flex:10%;
      justify-content:left;
      font-size:15px
    }
    .columns:nth-child(odd) { background:rgb(249, 249, 249)}
    .columns:nth-child(even) { background:#fff}
    </style>`
    returnData += `<div class="mainClass">`
    returnData += `<div class="header">${result.name}</div>`
    for (const match of result.data) {
      i++
      if (round === match.matchRound) {
        returnData += `<div class="columns">`
        returnData += `<div class="column date">`
        returnData += `<div style="padding: 10px">${match.startDate}<br>${match.startTime}</div>`
        returnData += `</div>`
        returnData += `<div class="column left">`
        returnData += `<span style="padding: 10px">${match.team1.name}</span>`
        returnData += `<img class="imageClass" width="30" src="${match.team1.logo}">`
        returnData += `</div>`
        returnData += `<div class="column result">`
        returnData += `<span style="padding: 10px"> ${match.score} </span>`
        returnData += `</div>`
        returnData += `<div class="column right">`
        returnData += `<img class="imageClass" width="40" src="${match.team2.logo}">`
        returnData += `<span style="padding: 10px">${match.team2.name}</span>`
        returnData += `</div>`
        returnData += `</div>`
      }
    }
    returnData += '</div>'
    return returnData
  }
}

export default getMatches
