import { useState } from 'react'
import { generateRandomNumber } from '../helper/common'

export const ScreenSettings = () => {
  const initialValue = {
    display: {
      backgroundColor: '#000000',
      marginTop: 0,
      color: '#ffffff'
    }
  }
  const [display, setDisplay] = useState(initialValue?.display)

  return (
    <div key={generateRandomNumber(1, 100000000)} style={display}>
      asdasd
    </div>
  )
}
