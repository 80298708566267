import AnalogClock, { Themes } from 'react-analog-clock'
const AnalogClockModule = props => {
  const { moduleSettings } = props

  const theme = Themes[moduleSettings.theme]
  const width = Number(moduleSettings.width)
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}
    >
      <AnalogClock
        theme={theme}
        width={width}
        showSmallTicks={moduleSettings.showSmallTicks}
      />
    </div>
  )
}
export default AnalogClockModule
