import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { parseJsonData } from '../../../helper/common'
import './Styles/MainMenuStyle.css'
import {
  getCustomerProductCategories,
  getCustomerProducts,
  restaurantMenu
} from '../../../api/getRestaurantMenu'
import MainMenuLandscape from './mainMenuLandscape'
import MainMenuPortrait from './mainMenuPortrait'
import Template_1 from './Templates/template_1'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet'

/**
 *
 * @param props
 * @returns
 */
const RestaurantMenuList = props => {
  const { moduleSettings, content } = props
  const { googleFontLink, parameters } = moduleSettings
  const [menuData, setMenuData] = useState()
  const [template, setTemplate] = useState(false)
  const [displayPrice, setDisplayPrice] = useState(false)

  const titleColor =
    parameters?.title_1_color?.mapValue?.fields?.value?.stringValue
  const lang = parameters?.language_code?.mapValue?.fields?.value?.stringValue
  const fontColor = parameters?.font_color?.mapValue?.fields?.value?.stringValue
  const currency = parameters?.currency?.mapValue?.fields?.value?.stringValue
  const allergens = parseJsonData(
    parameters?.json_data?.mapValue?.fields?.value?.stringValue
  )
  const menuId =
    parameters?.restaurant_menu_id?.mapValue?.fields?.value?.stringValue

  const templateId =
    parameters?.restaurant_template_id?.mapValue?.fields?.value?.stringValue

  const presentationOrientation =
    parameters?.presentation_orientation?.mapValue?.fields?.value?.stringValue
  const customerId = content?.parameters?.customerId

  useEffect(() => {
    const fetchData = async () => {
      const menu = await restaurantMenu(menuId, templateId)
      const products = await getCustomerProducts(customerId)
      const categories = await getCustomerProductCategories(customerId)
      switch (Number(templateId)) {
        // buono
        case 1:
          setTemplate(
            Template_1(
              menu?.data,
              products?.data?.products,
              categories?.data?.categories
            )
          )
      }

      setMenuData(menu?.data?.metadata)
      setDisplayPrice(menu?.data?.displayPrice)
    }
    fetchData()
  }, [])

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      {template}
    </HelmetProvider>
  )
}
export default RestaurantMenuList
