import SliderModule from './slider'

/**
 *
 * @param props
 * @returns
 */
const PdfReader = props => {
  const moduleSettings = props.moduleSettings
  // https://github.com/akiran/react-slick
  var sliderSettings = {
    autoplay: true,
    autoplaySpeed: Number(moduleSettings.duration),
    fade: moduleSettings.fade,
    infinite: true
  }
  let key = 0
  const images = moduleSettings.images
  let imageGalery = {}
  if (images) {
    imageGalery = Object.values(images).map(img => (
      <div key={key + 1}>
        <img style={img.style} src={img.path} alt={'datavision'} />
      </div>
    ))
    return <SliderModule settings={sliderSettings} content={imageGalery} />
  }
  return false
}
export default PdfReader
