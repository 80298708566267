import { prepareImage } from '../../../helper/common'
import SliderModule from '../slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/**
 *
 * @param props
 * @returns
 */
const MainMenuLandscape = (
  allergens,
  meals,
  soups,
  lang,
  foodTitle,
  foodTextActive,
  foodText,
  soupImage,
  mainStyle,
  scrollEffect,
  duration,
  displayPrice,
  currency,
  mealTitle,
  googleFontLink,
  soupTitle
) => {
  let divsMeal
  let divsSoup
  let allergensList = []
  if (allergens) {
    for (const key in allergens) {
      const number = Object.hasOwnProperty.call(
        allergens[key]['metadata'],
        'number'
      )
        ? allergens[key]['metadata']['number']
        : allergens[key]['id']
      allergensList.push(
        '(' + number + ') ' + allergens[key]['metadata'][lang] + ', '
      )
    }
  }
  if (meals) {
    divsMeal = meals.map((item, index) => {
      let imageSrc
      if (Object.hasOwnProperty.call(item, 'image')) {
        imageSrc = prepareImage(item.image)
      }
      return (
        <div key={index}>
          {imageSrc && <img src={imageSrc} alt={'datavision'} />}
          <div className='foodTitle' style={foodTitle}>
            {item.number} : {item.label}
          </div>
        </div>
      )
    })
  }
  if (soups) {
    divsSoup = soups.map((item, index) => {
      let imageSrc
      if (Object.hasOwnProperty.call(item, 'image')) {
        imageSrc = prepareImage(item.image)
      }
      return (
        <div key={index}>
          {imageSrc && (
            <img src={imageSrc} style={soupImage} alt={'datavision'} />
          )}
          <div className='soupTitle' style={foodTitle}>
            {item.label}
          </div>
        </div>
      )
    })
  }

  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: scrollEffect === 'fade' ? true : false,
    autoplaySpeed: duration
  }

  const settingsSoup = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplaySpeed: duration
  }
  if (divsMeal) {
    return (
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div className='landscape'>
          <div className='container1' style={mainStyle}>
            <div className='outer-container'>
              <div className='containerRestaurantMain'>
                <div className='allergens'>{allergensList}</div>
                <SliderModule settings={settings} content={divsMeal} />
              </div>
              <div className='container2'>
                <SliderModule settings={settingsSoup} content={divsSoup} />
                <div className='foodTitleList' style={foodTitle}>
                  {soupTitle}
                </div>
                {soups.map((item, index) => {
                  const price = item?.salePrice ? item?.salePrice : item?.price
                  return (
                    <table>
                      <tr>
                        <td className='mealLabel'>
                          <div
                            className='foodText'
                            style={item.active ? foodTextActive : foodText}
                          >
                            {item.number + ' : ' + item.label}{' '}
                            {item?.attributes && (
                              <small>( {item?.attributes} )</small>
                            )}
                          </div>
                        </td>
                        <td className='mealPrice'>
                          <div
                            className='foodText'
                            style={item.active ? foodTextActive : foodText}
                          >
                            {displayPrice &&
                              Number(price).toFixed(2) + ' ' + currency}
                          </div>
                        </td>
                      </tr>
                    </table>
                  )
                })}
                <div className='foodTitleList' style={foodTitle}>
                  {mealTitle}
                </div>
                {meals.map((item, index) => {
                  const price = item?.salePrice ? item?.salePrice : item?.price
                  return (
                    <table>
                      <tr>
                        <td className='mealLabel'>
                          <div
                            className='foodText'
                            style={item.active ? foodTextActive : foodText}
                          >
                            {item.number + ' : ' + item.label}{' '}
                            {item?.attributes && (
                              <small>( {item?.attributes} )</small>
                            )}
                          </div>
                        </td>
                        <td className='mealPrice'>
                          <div
                            className='foodText'
                            style={item.active ? foodTextActive : foodText}
                          >
                            {displayPrice &&
                              Number(price).toFixed(2) + ' ' + currency}
                          </div>
                        </td>
                      </tr>
                    </table>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    )
  }
}
export default MainMenuLandscape
