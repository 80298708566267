import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async';

/**
 *
 * @param props
 * @returns
 */
const RssReader = props => {
  const { moduleSettings, content } = props
  const googleFontLink = moduleSettings.googleFontLink
  if (moduleSettings.rssType === 'scroll') {
    return (
    <HelmetProvider>
        {googleFontLink && <Helmet><link href={googleFontLink} rel="stylesheet" /></Helmet>}
        <div dangerouslySetInnerHTML={{ __html: content.html }} />
      </HelmetProvider>
    )
  } else {
    var rssReaderSettings = {
      autoplay: true,
      autoplaySpeed: Number(moduleSettings.speed),
      fade: true,
      infinite: true
    }
    let key = 0
    const rssData = Object.entries(moduleSettings.rssData)
    const rssSliderData = rssData.map(message => (
      <div key={key + 1}>
        <div dangerouslySetInnerHTML={{ __html: message[1] }} />
      </div>
    ))
    return (
      <HelmetProvider>
        {googleFontLink && <Helmet><link href={googleFontLink} rel="stylesheet" /></Helmet>}
        <SliderModule settings={rssReaderSettings} content={rssSliderData} />
      </HelmetProvider>
    )
  }
}
export default RssReader
