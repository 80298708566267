import { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'

// Routes
import { Presentation } from './presentation'
import { Custom } from './custom'
import { Screen } from './screen'
import { ScreenSettings } from './screenSettings'
import { Rest } from './rest'
import { MenuUpdate } from './Restaurant-mobile-remote/MenuUpdate'

export const AppRoutes = () => {
  const handleKeyPress = useCallback(event => {
    if (event.key === 'q') {
      window.location.reload('https://www.google.com')
    }
  }, [])
  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])
  return (
    <Container>
      <Router>
        <Routes>
          <Route
            path='/presentation/:presentationId'
            element={<Presentation />}
          ></Route>
          <Route path='/screen/:screenUrl' element={<Screen />}></Route>
          <Route path='/custom/:customer' element={<Custom />} />
          <Route path='/menu-update/:url' element={<MenuUpdate />} />
          <Route path='/screen/settings' element={<ScreenSettings />} />
          <Route path='' element={<Rest />} />
        </Routes>
      </Router>
    </Container>
  )
}
