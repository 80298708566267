import { Helmet, HelmetProvider } from 'react-helmet-async'
import { generateRandomNumber } from '../../helper/common'
/**
 * returns formated clock
 * @param props
 * @returns
 */
const SimpleHtml = props => {
  const { moduleSettings, content } = props
  const googleFontLink = moduleSettings.googleFontLink
  return (
    <div>
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div
          key={generateRandomNumber(1, 100000000)}
          dangerouslySetInnerHTML={{ __html: content.html }}
        />
      </HelmetProvider>
    </div>
  )
}

export default SimpleHtml
