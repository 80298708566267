import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { WeatherIcon } from '../../helper/module/weatherIcon'
import 'moment/min/locales'
import { getMainStyle } from './settings/mainStyle'
import { removeAccents } from '../../helper/common'

/**
 *
 * @param props
 * @returns
 */
const Weather = props => {
  const moduleSettings = props?.moduleSettings
  const { googleFontLink, parameters } = moduleSettings
  const API_KEY = process.env.REACT_APP_WEATHER_API_KEY
  const googlePlace = removeAccents(parameters?.google_place?.stringValue)
  const iconColor = parameters?.icon_color?.stringValue
  const iconSize = Number(parameters?.icon_size?.stringValue)
  const language =
    parameters?.language_code?.stringValue === 'cz'
      ? 'cs'
      : parameters?.language_code?.stringValue
  const forecastDays = Number(parameters?.forecast_days?.stringValue)
  const unitNumber = Number(parameters?.temperature_unit?.stringValue)
  const unit = unitNumber === 0 ? '°C' : '°F'
  const fontColor = parameters?.font_color?.stringValue
  const fontSize = Number(parameters?.font_size?.stringValue)
  const [weatherResult, setWeatherResult] = useState()
  let forecastPercent
  let displayForecast = false
  let days = 0
  let forecastCenter = ''
  let forecastDayWidth = '0'
  if (forecastDays === 1) {
    displayForecast = true
    forecastPercent = 55
    days = 4
    forecastDayWidth = '50%'
  } else if (forecastDays === 2) {
    displayForecast = true
    forecastPercent = 35
    days = 6
    forecastCenter = 'center'
    forecastDayWidth = '0'
  }

  moment.locale(language)

  useEffect(() => {
    const url =
      process.env.REACT_APP_WEATHER_URL +
      `forecast.json?key=${API_KEY}&q=${googlePlace}&days=${days}&aqi=no&alerts=no&lang=${language}`

    const fetchWeather = async () => {
      const response = await axios
        .get(url, {
          headers: {
            'Content-type': 'application/json'
          }
        })
        .then(res => {
          return res.data
        })
        .catch(err => {
          // Sentry.captureException(err)
          console.log(err)
        })
      setWeatherResult(response)
    }
    fetchWeather()
  }, [])
  if (!weatherResult) {
    return <></>
  }
  const { current, forecast, location } = weatherResult
  const dayPart = current.is_day === 1 ? 'day' : 'night'
  const icon = WeatherIcon(current.condition.code, iconSize, iconColor, dayPart)
  const today = {
    temp: Math.round(unitNumber === 0 ? current.temp_c : current.temp_f),
    icon: icon,
    text: current.condition.text
  }
  const todayContainer = {
    display: 'flex'
  }
  const mainStyle = getMainStyle(parameters)
  mainStyle.padding = '5%'

  const titleStyle = {
    width: '90%',
    color: fontColor,
    fontSize: (fontSize / 100) * 30 + 'px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: '5%'
  }
  const triangle = {
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 40px 40px 0',
    borderColor: `transparent ${iconColor} transparent transparent`
  }
  const todayTemperature = {
    color: fontColor,
    fontSize: fontSize + 'px',
    verticalAlign: 'middle',
    display: 'inline-block'
  }
  const todayIcon = {
    verticalAlign: 'middle',
    display: 'inline-block'
  }

  const forecastContainer = {
    display: 'flex',
    marginTop: '2%'
  }

  const forecastIcon = {
    verticalAlign: 'middle',
    display: 'inline-block'
  }
  const forecastTemperature = {
    color: fontColor,
    fontSize: (((fontSize / 100) * forecastPercent) / 100) * 50 + 'px',
    verticalAlign: 'middle',
    display: 'inline-block',
    textAlign: forecastCenter
  }
  const forecastDayStyle = {
    marginRight: '5%',
    textAlign: forecastCenter
    // width: '50%'
  }
  const forecastDayText = {
    color: fontColor,
    textTransform: 'uppercase',
    fontSize: (((fontSize / 100) * forecastPercent) / 100) * 50 + 'px',
    paddingLeft: '10%'
  }

  // forecast
  let counter = 0
  let forecastData = []
  for (const item of forecast.forecastday) {
    if (counter > 0 && counter <= days) {
      const oneDate = moment(item.date, 'YYYY-MM-DD')
      const dayName = oneDate.format('ddd')
      const day = item.day
      forecastData.push({
        name: dayName,
        temp: Math.round(unitNumber === 0 ? day.avgtemp_c : item.day.avgtemp_f),
        text: day.condition.text,
        icon: WeatherIcon(
          day.condition.code,
          (iconSize / 100) * forecastPercent,
          iconColor
        )
      })
    }
    counter++
  }

  const forecastDaysDiv = forecastData.map(day => {
    return (
      <span style={forecastDayStyle}>
        <div style={forecastDayText}>{day.name}</div>
        <span style={forecastIcon}>{day.icon}</span>
        <span style={forecastTemperature}>{day.temp}°</span>
      </span>
    )
  })
  return (
    <>
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
      </HelmetProvider>
      <div style={mainStyle}>
        <div style={todayContainer}>
          <div style={titleStyle}>{today.text}</div>
        </div>
        <div>
          <span style={todayIcon}>{today.icon}</span>{' '}
          <span style={todayTemperature}>{today.temp + unit}</span>
        </div>
        {displayForecast && (
          <div style={forecastContainer}>{forecastDaysDiv}</div>
        )}
      </div>
    </>
  )
}
export default Weather
