import axios from 'axios'

export const getData = async () => {
  const url =
    process.env.REACT_APP_SYMFONY_HOST +
    `/V1/menu-update?url=42ba9675e015ba549f345936073b965c`
  const config = {
    method: 'get',
    url: url,
    headers: {}
  }

  return await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return error
    })
}
