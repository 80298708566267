import axios from 'axios'

export const postData = async data => {
  const url = process.env.REACT_APP_SYMFONY_HOST + `/fenixTimes`
  const config = {
    method: 'post',
    url: url,
    headers: {},
    data: JSON.stringify(data)
  }

  return await axios(config)
    .then(function (response) {
      return { status: response.status, statusText: response.statusText }
    })
    .catch(function (error) {
      console.log(error)
      return error
    })
}
