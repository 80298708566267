import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import FormSection1 from './Fenix-spa/form-section-1'
import FormSection2 from './Fenix-spa/form-section-2'
import './Styles/fenix.css'
import { getData } from './Fenix-spa/getData'
import { postData } from './Fenix-spa/postData'

export const Custom = () => {
  const [initialValues, setInitialValues] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [buttonName, setButtonName] = useState('Aktualizovat')
  useEffect(() => {
    const fetchData = async () => {
      const data = await getData()
      setInitialValues(data)
    }
    fetchData()
  }, [])

  const handleSubmit = async values => {
    const result = await postData(values)
    // display message
    setButtonName('Obrazovka byla aktualizována')
    setSubmitted(true)
    setInterval(() => {
      window.location.reload()
    }, 3000)
  }
  return (
    <div className='customForm'>
      <HelmetProvider>
        <Helmet>
          <link
            rel='stylesheet'
            href='https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css'
            integrity='sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi'
            crossorigin='anonymous'
          />
          <body class='fenixBody' />
        </Helmet>
        <Row>
          <Col>
            {initialValues && Object.keys(initialValues).length !== 0 && (
              <>
                <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    isSubmitting
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Col>
                        <FormSection1 handleChange errors />
                      </Col>
                      <Col>
                        <FormSection2 handleChange errors />
                      </Col>

                      <Col className='fenixBtn'>
                        <Button
                          disabled={!isValid || submitted}
                          variant='success'
                          as='input'
                          size='lg'
                          type='submit'
                          value={buttonName}
                        />
                      </Col>
                      {/* <Col>
                        <pre style={{ margin: '0 auto' }}>
                          {JSON.stringify(
                            { ...values, ...errors, isValid, isSubmitting },
                            null,
                            2
                          )}
                        </pre>
                      </Col> */}
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Col>
        </Row>
      </HelmetProvider>
    </div>
  )
}
