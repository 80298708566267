// Libs
import { createGlobalStyle } from 'styled-components'

// Theme Parts
import { colors } from './colors'

// Image
import backgroundImg from '../../../assets/img/datavisionLogo.png'

export const defaultTheme = {
  colors: colors
}

export const GlobalStyles = createGlobalStyle`
    body {
      background-color: ${props => props.theme.colors.primary};
      background-image: url(${backgroundImg});
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: top 50% left 50%;
      // color: ${props => props.theme.colors.secondary};
      // font-family: Open Sans; 
    }
    *{
      -ms-overflow-style: none;
    }
    ::-webkit-scrollbar {
        display: none;
    }
`
