import * as Sentry from '@sentry/react'
// axios
import axios from 'axios'

export const restaurantMenu = (id, templateId = 0) => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/restaurant/getMenuById?id=${id}&templateId=${templateId}`
    return axios
      .get(url, {
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(res => {
        resolve({
          data: res.data.result.data
        })
      })
      .catch(err => {
        Sentry.captureException(err)
        console.log(err)
      })
  })
}

export const getCustomerProducts = id => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_API_URL + `/restaurant/getCustomerProducts?id=${id}`
    return axios
      .get(url, {
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(res => {
        resolve({
          data: res.data.result.data
        })
      })
      .catch(err => {
        Sentry.captureException(err)
        console.log(err)
      })
  })
}
export const getCustomerProductCategories = id => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/restaurant/getCustomerProductCategories?id=${id}`
    return axios
      .get(url, {
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(res => {
        resolve({
          data: res.data.result.data
        })
      })
      .catch(err => {
        Sentry.captureException(err)
        console.log(err)
      })
  })
}
