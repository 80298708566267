import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/**
 *
 * @param props
 * @returns
 */
const FlashMessages = props => {
  const { moduleSettings, content } = props
  const googleFontLink = moduleSettings.googleFontLink
  const slides = content?.parameters?.slides

  let key = 0
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = () => {
      setIndex(prevIndex => {
        if (prevIndex === Object.keys(slides).length - 1) {
          return 0
        }
        return prevIndex + 1
      })
    }
    setInterval(timer, Number(moduleSettings.duration))
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div
        key={key + 1}
        style={moduleSettings.moduleCss}
        dangerouslySetInnerHTML={{ __html: slides[index] }}
      />
    </HelmetProvider>
  )
}

export default FlashMessages
