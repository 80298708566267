import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { generateRandomNumber } from '../../helper/common'

/**
 * returns formated clock
 * @param props
 * @returns
 */
const Clock = props => {
  const { moduleSettings } = props
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date())
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  })
  const displaySeconds = moduleSettings.displaySeconds
  const moduleCss = moduleSettings.moduleCss
  const googleFontLink = moduleSettings.googleFontLink
  return (
    <div>
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div key={generateRandomNumber(1, 1110000000)} style={moduleCss}>
          {displaySeconds ? format(time, 'HH:mm:ss') : format(time, 'HH:mm')}
        </div>
      </HelmetProvider>
    </div>
  )
}

export default Clock
