import SliderModule from '../slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { createTittleStyle } from '../../../helper/module/styleMaker'
import { getMainStyle } from '../settings/mainStyle'
import { GetCanteenMenuByType } from '../../../helper/module/school/canteenMenu'
import moment from 'moment/moment'

/**
 *
 * @param props
 * @returns
 */
const SchoolCanteen = props => {
  const { moduleSettings, block } = props
  const { googleFontLink, parameters } = moduleSettings

  const blockHeight = Number(block.css.height.replace('px', ''))
  const fontSize = Number(parameters?.font_size?.stringValue)
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplaySpeed: Number(parameters.scroll_speed_number.stringValue) * 1000
  }
  // added left / right padding
  const padding =
    parameters?.text_padding_left_right?.stringValue === 'on' ? true : false
  let textPadding = (blockHeight - fontSize) / 2 + 'px 0 '
  if (padding) {
    textPadding = (blockHeight - fontSize) / 2 + 'px 2% 0 2%'
  }

  const mainStyle = getMainStyle(parameters)
  mainStyle.padding = textPadding
  mainStyle.width = block.css.width
  mainStyle.height = block.css.height
  mainStyle.textAlign = parameters?.title_1_alignment?.stringValue

  // test with exact dates
  // const todayDate = moment('2023-01-16').format('YYYY-MM-DD')
  // const tommorowDate = moment('2023-01-16').add(1, 'days').format('YYYY-MM-DD')

  // const todayFormated = moment('2023-01-16').format('D.M.YYYY')
  // const tomorrowFormated = moment('2023-01-16')
  //   .add(1, 'days')
  //   .format('D.M.YYYY')

  const todayDate = moment().format('YYYY-MM-DD')
  const tommorowDate = moment().add(1, 'days').format('YYYY-MM-DD')

  const todayFormated = moment().format('D.M.YYYY')
  const tomorrowFormated = moment().add(1, 'days').format('D.M.YYYY')

  const menuType = parameters?.text_input?.stringValue

  let menu

  switch (menuType) {
    case '2':
      menu = GetCanteenMenuByType(
        menuType,
        parameters?.menu_data?.stringValue,
        todayFormated,
        tomorrowFormated
      )
      break
    default:
      menu = GetCanteenMenuByType(
        menuType,
        parameters?.menu_data?.mapValue?.fields,
        todayDate,
        tommorowDate
      )
  }

  let divs
  const tittle1Style = createTittleStyle('title1', parameters)
  const textStyle = createTittleStyle('text', parameters)
  textStyle.textAlign = parameters?.title_1_alignment?.stringValue

  let todayName = 'Today'
  let tomorrowName = 'Tomorrow'
  let warning = 'The menu is currently unavailable'
  switch (parameters?.language_code?.stringValue) {
    case 'sk':
      todayName = 'Dnes'
      tomorrowName = 'Zajtra'
      warning = 'Jedálny lístok je momentálne nedostupný'
      break
    case 'cz':
      todayName = 'Dnes'
      tomorrowName = 'Zítra'
      warning = 'Menu je momentálně nedostupné'
      break
    default:
      todayName = 'Today'
      tomorrowName = 'Tomorrow'
      warning = 'The menu is currently unavailable'
  }
  if (menu) {
    divs = Object.keys(menu).map(key => {
      const item = menu[key]
      const title =
        key === '0' ? 'Dnes: ' + todayFormated : 'Zajtra: ' + tomorrowFormated
      let text = ''
      if (item !== undefined) {
        text = item.replace(/<br><br>/g, '<br>')
      }
      return (
        <div key={(key = key + 1)}>
          {tittle1Style && title && <div style={tittle1Style}>{title}</div>}
          {textStyle && text && (
            <div style={textStyle} dangerouslySetInnerHTML={{ __html: text }} />
          )}
        </div>
      )
    })
  }

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={mainStyle}>
        {divs.length > 0 && <SliderModule settings={settings} content={divs} />}
        {divs.length === 0 && <div style={tittle1Style}>{warning}</div>}
      </div>
    </HelmetProvider>
  )
}
export default SchoolCanteen
