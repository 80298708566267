export const preprocessData = async param => {
  const types = ['integerValue', 'stringValue', 'mapValue', 'booleanValue']
  for (const type of types) {
    if (type === param.valueType) {
      if (type === 'mapValue') {
        return param['mapValue']['fields']
      } else if (type === 'stringValue') {
        const value = param[type]
        const returnData = value.replace('\r', '')
        return returnData
      } else {
        return param[type]
      }
    }
  }
}
