import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Slider/slider.css'

const SliderModule = props => {
  return (
    <div>
      <Slider {...props.settings}>{props.content}</Slider>
    </div>
  )
}

export default SliderModule
