import { isObjectEmpty, prepareTimestampFromSeconds } from '../../helper/common'

export const updateActiveBlocks = blocks => {
  let blocksData = {}
  for (const blockId of Object.keys(blocks)) {
    const activeModule = prepareModule(blockId, blocks)
    blocksData[blockId] = activeModule
  }
  // update local storage
  localStorage.setItem('active_blocks', JSON.stringify(blocksData))
}

/**
 *
 * @param {*} activeBlocks
 * @param {*} blocks
 * @param {*} blockId
 */
export const updateSingleBlock = (
  activeBlocks,
  blocks,
  blockId,
  activeModuleId
) => {
  const activeModule = prepareModule(blockId, blocks, activeModuleId)
  activeBlocks[blockId] = activeModule
  // update local storage
  localStorage.setItem('active_blocks', JSON.stringify(activeBlocks))
  return activeModule.moduleId
}

/**
 *
 * @param {*} blockId
 * @param {*} blocks
 * @returns
 */
const prepareModule = (blockId, blocks, activeModuleId = false) => {
  if (Object.hasOwnProperty.call(blocks[blockId], 'modules')) {
    const modules = setModulesByOrder(blocks[blockId].modules)
    const count = Object.keys(modules).length

    if (count > 1) {
      // check if is module already set in the object in LS
      if (!activeModuleId) {
        activeModuleId = checkActiveModule(blockId)
      }
      if (activeModuleId) {
        return nextModule(modules, activeModuleId)
      } else {
        // setup first module
        return firstModule(modules)
      }
    }
  }
  return false
}

/**
 *
 * @param {*} blockId
 * @returns
 */
export const checkActiveModule = blockId => {
  const activeBlocks = JSON.parse(localStorage.getItem('active_blocks'))

  if (!activeBlocks) {
    return false
  }
  for (const activeBlockId of Object.keys(activeBlocks)) {
    if (activeBlockId === blockId) {
      return activeBlocks[activeBlockId].moduleId
    }
  }
}

/**
 *
 * @param {*} modules
 * @returns
 */
const firstModule = modules => {
  let counter = 0
  for (const key of Object.keys(modules)) {
    counter++
    if (counter === 1) {
      const module = modules[key]
      const { duration, moduleId } = module
      return prepareModuleData(duration, moduleId)
    }
  }
}

/**
 *
 * @param {*} duration
 * @param {*} moduleId
 * @returns
 */
const prepareModuleData = (duration, moduleId) => {
  const timestamp = prepareTimestampFromSeconds(duration)
  return {
    moduleId,
    timestamp
  }
}

/**
 *
 * @param {*} modules
 * @param {*} activeModuleId
 * @returns
 */
const nextModule = (modules, activeModuleId) => {
  let activeModule = {}
  for (const key of Object.keys(modules)) {
    const module = modules[key]
    const { duration, moduleId } = module
    if (!isObjectEmpty(activeModule)) {
      // set next module
      return prepareModuleData(duration, moduleId)
    }
    if (activeModuleId === moduleId) {
      activeModule = module
    }
  }
  const firstKey = Object.keys(modules)[0]
  const firstModule = modules[firstKey]
  // if active module is last module
  if (!isObjectEmpty(activeModule)) {
    return prepareModuleData(firstModule.duration, firstModule.moduleId)
  }
}

/**
 *
 * @param {*} modules
 * @returns
 */
const setModulesByOrder = modules => {
  let updated = {}
  for (const moduleId of Object.keys(modules)) {
    let module = {}
    module = Object.assign({}, modules[moduleId])
    module.moduleId = moduleId

    const order = Number(module.order)
    updated[order] = module
  }
  return updated
}
