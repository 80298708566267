import { prepareBlockCss, prepareBlockModules } from './prepareBlock'
import { preprocessData } from './preprocessData'
import { isObjectEmpty } from './common'

export const preprocessPresentationData = async presentation => {
  const blocks = await parseBlocks(presentation.blocks?.mapValue?.fields)
  const presentationSettings =
    presentation?.presentation_settings?.arrayValue?.values
  let fields = []
  let returnData = {}
  let specialData
  if (presentationSettings) {
    specialData = await preprocessData(presentation?.specialData)
    for (const field of presentationSettings) {
      const parameterCode = await preprocessData(field.mapValue.fields.code)
      const fieldData = {
        id: await preprocessData(field.mapValue.fields.id),
        presentationId: await preprocessData(
          field.mapValue.fields.presentationId
        ),
        parameterId: await preprocessData(field.mapValue.fields.parameterId),
        value: await preprocessData(field.mapValue.fields.value),
        code: parameterCode
      }
      fields.push(fieldData)
      if (parameterCode === 'css_style') {
        const cssData = JSON.parse(fieldData.value)
        if (presentation?.customFont?.stringValue) {
          cssData.fontFamily = presentation?.customFont?.stringValue
        }
        returnData.css = cssData
      }
    }
  }
  if (!isObjectEmpty(blocks)) {
    returnData.blocks = blocks
  }
  if (specialData) {
    returnData.specialData = specialData
  }
  return returnData
}

/**
 *
 * @param {*} blocks
 * @returns
 */
const parseBlocks = async blocks => {
  let newBlocks = {}
  for (const item of Object.values(blocks)) {
    let block = {}
    const blockData = item.mapValue.fields
    const blockId =
      blockData.block_settings.mapValue.fields.block_id.integerValue
    block.css = await prepareBlockCss(blockData)
    if (Object.hasOwnProperty.call(blockData, 'modules')) {
      block.modules = await prepareBlockModules(blockData)
    }
    newBlocks[blockId] = block
  }
  return newBlocks
}
