/**
 *
 * @param {*} parameters
 * @returns
 */
export const jumpSchedulerBa1 = parameters => {
  return [
    {
      time: '00:05',
      type: 'countdown',
      value: 2
    },
    {
      time: '10:00',
      type: 'video',
      value: '251708267.mp4'
    },
    {
      time: '11:40',
      type: 'video',
      value: '250256534.mp4'
    },
    {
      time: '13:00',
      type: 'video',
      value: '251708543.mp4'
    },
    {
      time: '14:40',
      type: 'video',
      value: '250636456.mp4'
    },
    {
      time: '18:00',
      type: 'countdown',
      value: 2
    },
    {
      time: '25:00',
      type: 'video',
      value: '224178715.mp4'
    },
    {
      time: '27:50',
      type: 'countdown',
      value: 3
    },
    {
      time: '30:00',
      type: 'countdown',
      value: 2
    },
    {
      time: '40:00',
      type: 'video',
      value: '250637498.mp4'
    },
    {
      time: '42:10',
      type: 'video',
      value: '252710901.mp4'
    },
    {
      time: '43:00',
      type: 'video',
      value: '374375296.mp4'
    },
    {
      time: '45:00',
      type: 'video',
      value: '374379968.mp4'
    },
    {
      time: '46:30',
      type: 'countdown',
      value: 2
    },
    {
      time: '55:00',
      type: 'video',
      value: '224178715.mp4'
    },
    {
      time: '57:50',
      type: 'countdown',
      value: 3
    }
  ]
}
