import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { getData } from './getData'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import moment from 'moment'
import './Styles/restaurant.css'

export const MenuUpdate = () => {
  const [initialValues, setInitialValues] = useState(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      const data = await getData()
      setInitialValues(data)
    }
    fetchData()
  }, [])

  const handleChange = event => {
    console.log('event:: ', event.checked)
    // setChecked(event.target.checked)
  }

  let divsSoup
  let divsMeal
  if (initialValues) {
    const today = moment()
    const now = today.format('YYYY-MM-DD')
    const data = initialValues?.response
    const hasData = data.hasOwnProperty(now)
    if (hasData) {
      const menu = data[now]
      const soups = menu.soup
      const meals = menu.meal
      if (soups) {
        divsSoup = soups.map((item, index) => {
          const checked = item.active
          // setChecked(item.active)
          return (
            <div key={index}>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label={item.label}
              />
            </div>
          )
        })
      }
      // if (meals) {
      //   divsMeal = meals.map((item, index) => {
      //     setChecked(item.active)
      //     return (
      //       <div key={index}>
      //         <FormControlLabel
      //           control={<Checkbox checked={checked} />}
      //           label={item.label}
      //         />
      //       </div>
      //     )
      //   })
      // }
    }
  }
  const handleSubmit = async values => {
    // const result = await postData(values)
    // // display message
    // setButtonName('Obrazovka byla aktualizována')
    // setSubmitted(true)
    // setInterval(() => {
    //   window.location.reload()
    // }, 3000)
  }
  return (
    <HelmetProvider>
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css'
          integrity='sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi'
          crossorigin='anonymous'
        />
        <body class='restaurantMenu' />
      </Helmet>
      <div>{divsSoup}</div>
      {/* <div>{divsMeal}</div> */}
    </HelmetProvider>
  )
}
