import moment from 'moment'
import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { parseJsonData } from '../../../helper/common'
import { createTittleStyle } from '../../../helper/module/styleMaker'
import { getMainStyle } from '../settings/mainStyle'
import { useEffect, useState } from 'react'

const dateTimeFormat = 'DD-MM-YYYY HH:mm:ss'

/**
 *
 * @param props
 * @returns
 */
const SchoolLessons = props => {
  const { moduleSettings, block } = props
  const { googleFontLink, parameters } = moduleSettings
  const jsonData = parameters?.json_data?.stringValue
  const lessons = jsonData ? parseJsonData(jsonData) : false
  const fontSize = Number(parameters?.title_1_font_size?.stringValue)

  let title
  // const [result, setResult] = useState()

  const tittleStyle = createTittleStyle('title1', parameters)
  const textPercent = 150
  const textStyle = {
    fontWeight: 'normal',
    color: parameters?.text_color?.stringValue,
    fontSize: (fontSize / 100) * textPercent + 'px',
    padding: '5%'
  }
  const mainStyle = getMainStyle(parameters)
  mainStyle.width = block.css.width
  mainStyle.height = block.css.height

  const data = getDuration(lessons)
  if (data.type === 'break') {
    title = parameters?.title_2?.stringValue
  } else {
    title = parameters?.title_1?.stringValue
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // update data
      const intervalId = setInterval(() => {
        window.location.reload()
      }, 2000)

      clearInterval(intervalId)
    } else {
      // Render a countdown
      if (hours) {
        return (
          <span>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        )
      } else {
        return (
          <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        )
      }
    }
  }

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={mainStyle}>
        {tittleStyle && title && <div style={tittleStyle}>{title}</div>}
        {textStyle && (
          <div style={textStyle}>
            {data && (
              <Countdown date={Date.now() + data.seconds} renderer={renderer} />
            )}
          </div>
        )}
      </div>
    </HelmetProvider>
  )
}
export default SchoolLessons

const getDuration = data => {
  // test data
  // const data = {
  //   lessons: [
  //     {
  //       start: '18:17',
  //       end: '19:10'
  //     },
  //     {
  //       start: '19:11',
  //       end: '19:12'
  //     }
  //   ]
  // }
  const today = moment(new Date()).format('DD-MM-YYYY')
  const actTime = moment(new Date(), dateTimeFormat)
  const { lessons } = data
  if (data) {
    // prepare data
    let updatedData = []
    let nextStart
    for (const key in lessons) {
      const lesson = lessons[key]
      if (lessons[Number(key) + 1]) {
        nextStart = lessons[Number(key) + 1].start
      }
      updatedData.push({
        lessonStart: lesson.start + ':00',
        lessonEnd: lesson.end + ':00',
        breakStart: lesson.end + ':01',
        breakEnd: nextStart + ':00'
      })
      nextStart = ''
    }

    return getType(updatedData, today, actTime)
  }
  return false
}

const getType = (updatedData, today, actTime) => {
  let seconds = 0
  let type = 'lesson'
  let isValidTime = false
  for (const key in updatedData) {
    const lesson = updatedData[key]
    const lessonStartTime = moment(
      today + ' ' + lesson.lessonStart,
      dateTimeFormat
    )
    const lessonEndTime = moment(today + ' ' + lesson.lessonEnd, dateTimeFormat)

    const breakStartTime = moment(
      today + ' ' + lesson.breakStart,
      dateTimeFormat
    )
    const breakEndTime = moment(today + ' ' + lesson.breakEnd, dateTimeFormat)

    if (lessonStartTime <= actTime && lessonEndTime >= actTime) {
      const secondsDiff = lessonEndTime.diff(actTime, 'seconds')
      seconds = secondsDiff * 1000
      isValidTime = true
    }
    if (breakStartTime <= actTime && breakEndTime >= actTime) {
      const secondsDiff = breakEndTime.diff(actTime, 'seconds')
      seconds = secondsDiff * 1000
      type = 'break'
      isValidTime = true
    }
  }
  if (!isValidTime) {
    const tomorrow = moment(new Date()).add(1, 'days').format('DD-MM-YYYY')
    const firstLesson = updatedData[0]
    const firstLessonStartTime = moment(
      tomorrow + ' ' + firstLesson.lessonStart,
      dateTimeFormat
    )
    seconds = firstLessonStartTime.diff(actTime, 'seconds') * 1000
    type = 'lesson'
  }

  return { seconds, type }
}
