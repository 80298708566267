import * as Sentry from '@sentry/react'
// axios
import axios from 'axios'

export const screenData = async screeenId => {
  const url = process.env.REACT_APP_API_URL + `/screen?url=${screeenId}`
  const result = await axios({
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.data.result
    })
    .catch(error => {
      Sentry.captureException(error)
      console.log(error)
      return false
    })
  if (result === false) {
    return result
  }

  let lastPresentationId = ''
  let id = ''
  let css = {
    width: '1920x',
    height: '1080px',
    border: '0',
    overflow: 'hidden!important'
  }
  if (Object.hasOwnProperty.call(result.lastPresentationId, 'integerValue')) {
    lastPresentationId = result.lastPresentationId.integerValue
  } else {
    lastPresentationId = result.lastPresentationId.stringValue
  }
  if (Object.hasOwnProperty.call(result.screenWidth, 'integerValue')) {
    css.width = result.screenWidth.integerValue + 'px'
  }
  if (Object.hasOwnProperty.call(result.screenHeight, 'integerValue')) {
    css.height = result.screenHeight.integerValue + 'px'
  }
  if (Object.hasOwnProperty.call(result.id, 'integerValue')) {
    id = result.id.integerValue
  } else {
    id = result.id.stringValue
  }
  const presentations = result.screen_presentations
  const screenScheduler = result.screen_scheduler
  let schedulerActive = false
  if (Object.hasOwnProperty.call(result, 'schedulerActive')) {
    schedulerActive = result.schedulerActive.booleanValue
  }
  // let canDisplay = true
  // if (Object.hasOwnProperty.call(result, 'canDisplay')) {
  //   if (Object.hasOwnProperty.call(result.canDisplay, 'booleanValue')) {
  //     canDisplay = result.canDisplay.booleanValue
  //   }
  // }

  // if (!canDisplay) {
  //   return false
  // }

  return {
    lastPresentationId,
    presentations,
    css,
    id,
    schedulerActive,
    screenScheduler
  }
}
