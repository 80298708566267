/**
 *
 * @param {*} parameters
 * @returns
 */
export const getMainStyle = parameters => {
  return {
    fontFamily: parameters?.google_font?.stringValue,
    fontWeight: parameters?.google_font_variant?.stringValue
  }
}
