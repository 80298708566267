import { prepareImage } from '../../../helper/common'
import SliderModule from '../slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/**
 *
 * @param props
 * @returns
 */
const MainMenuPortrait = (
  allergens,
  meals,
  soups,
  lang,
  foodTitle,
  foodTextActive,
  foodText,
  soupImage,
  mainStyle,
  scrollEffect,
  duration,
  displayPrice,
  currency,
  mealTitle,
  googleFontLink,
  soupTitle
) => {
  let divsMeal
  let divsSoup
  let allergensList = []
  let allMeals = []
  if (meals && soups) {
    for (const key in soups) {
      if (Object.hasOwnProperty.call(soups, key)) {
        const element = soups[key]
        allMeals.push(element)
      }
    }
    for (const key in meals) {
      if (Object.hasOwnProperty.call(meals, key)) {
        const element = meals[key]
        allMeals.push(element)
      }
    }
  }

  if (allergens) {
    for (const key in allergens) {
      const number = Object.hasOwnProperty.call(
        allergens[key]['metadata'],
        'number'
      )
        ? allergens[key]['metadata']['number']
        : allergens[key]['id']
      allergensList.push(
        '(' + number + ') ' + allergens[key]['metadata'][lang] + ', '
      )
    }
  }
  if (allMeals.length > 0) {
    divsMeal = allMeals.map((item, index) => {
      let imageSrc
      if (Object.hasOwnProperty.call(item, 'image')) {
        imageSrc = prepareImage(item.image)
      }
      return (
        <div key={index}>
          {imageSrc && <img src={imageSrc} alt={'datavision'} />}
          <div className='foodTitle' style={foodTitle}>
            {item.label}
          </div>
        </div>
      )
    })
  }

  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: scrollEffect === 'fade' ? true : false,
    autoplaySpeed: duration
  }

  const settingsSoup = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplaySpeed: duration
  }
  if (divsMeal) {
    return (
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div className='portrait'>
          <div className='container1' style={mainStyle}>
            <div className='allergens'>{allergensList}</div>
            <SliderModule settings={settings} content={divsMeal} />
          </div>
          <div className='container2'>
            <div className='foodTitleList' style={foodTitle}>
              {soupTitle}
            </div>
            {soups.map((item, index) => {
              const price = item?.salePrice ? item?.salePrice : item?.price
              return (
                <table>
                  <tr>
                    <td className='mealLabel'>
                      <div
                        className='foodText'
                        style={item.active ? foodTextActive : foodText}
                      >
                        {item.number + ' : ' + item.label}{' '}
                        {item?.attributes && (
                          <small>( {item?.attributes} )</small>
                        )}
                      </div>
                    </td>
                    <td className='mealPrice'>
                      <div
                        className='foodText'
                        style={item.active ? foodTextActive : foodText}
                      >
                        {displayPrice &&
                          Number(price).toFixed(2) + ' ' + currency}
                      </div>
                    </td>
                  </tr>
                </table>
              )
            })}
            <div className='foodTitleList' style={foodTitle}>
              {mealTitle}
            </div>
            {meals.map((item, index) => {
              const price = item?.salePrice ? item?.salePrice : item?.price
              return (
                <table>
                  <tr>
                    <td className='mealLabel'>
                      <div
                        className='foodText'
                        style={item.active ? foodTextActive : foodText}
                      >
                        {item.number + ' : ' + item.label}{' '}
                        {item?.attributes && (
                          <small>( {item?.attributes} )</small>
                        )}
                      </div>
                    </td>
                    <td className='mealPrice'>
                      <div
                        className='foodText'
                        style={item.active ? foodTextActive : foodText}
                      >
                        {displayPrice &&
                          Number(price).toFixed(2) + ' ' + currency}
                      </div>
                    </td>
                  </tr>
                </table>
              )
            })}
          </div>
        </div>
      </HelmetProvider>
    )
  }
}
export default MainMenuPortrait
