import './Styles/Style1.css'

const Template_1 = (menu, products, categories) => {
  const veganIcon =
    'https://login.datavision-online.eu/uploads/files/33/Images/dc52bf4ac0399be7cd21dcb24021aeee.png'
  const allProducts = Object.assign({}, menu?.metadata?.products)
  let gelato = []
  let drinks = []
  let coffee = []
  let sweet = []

  const keys = Object.keys(allProducts)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const productsData = allProducts[key]
    if (productsData.length > 0) {
      for (let x = 0; x < productsData.length; x++) {
        const product = productsData[x]
        if (product?.active === true) {
          switch (product?.category) {
            case '19':
              drinks.push(product)
              break
            case '20':
              coffee.push(product)
              break
            case '21':
              sweet.push(product)
              break
            case '22':
              gelato.push(product)
              break
          }
        }
      }
    }
    // do something with the key and value
  }
  return (
    <div className='large_container'>
      <div className='third'>
        <div className='headline bottom_marg'>
          <div className='headline_icon'>
            <img src={categories[22]?.image} alt='image' />
          </div>
          <div className='headline_text'>{categories[22]?.label?.sk}</div>
          <div className='headline_price' />
        </div>
        {/* HEADLINE */}
        <div className='info_box left_pad bottom_marg icecream_pricing'>
          <div className='ice_price_row'>
            <span>{categories[32]?.label?.sk}</span>
            <img src={categories[32]?.image} alt='image' />
            <span>{categories[33]?.label?.sk}</span>
            <img src={categories[33]?.image} alt='image' />
            <br />
          </div>
          <div className='ice_price_row'>
            <img src={categories[34]?.image} alt='image' />
            <span>
              {categories[34]?.label?.sk} -{' '}
              <span className='ice_price'>{categories[34]?.price} </span>
            </span>
          </div>
          <div className='ice_price_row'>
            <img src={categories[35]?.image} alt='image' />
            <span>
              {categories[35]?.label?.sk} -{' '}
              <span className='ice_price'>{categories[35]?.price} </span>
            </span>
          </div>
          <div className='ice_price_row'>
            <img src={categories[36]?.image} alt='image' />
            <span>
              {categories[36]?.label?.sk} -{' '}
              <span className='ice_price'>{categories[36]?.price} </span>
            </span>
          </div>
          <div className='ice_price_row'>
            <img src={categories[38]?.image} alt='image' />
            <span>
              {categories[38]?.label?.sk} (
              <span className='ice_price'>{categories[38]?.price} </span>){' '}
              {categories[37]?.label?.sk}{' '}
              <span className='ice_price'>{categories[37]?.price}</span>
            </span>
          </div>
          <div className='ice_price_row'>
            <img src={categories[39]?.image} alt='image' />
            <span>
              {categories[39]?.label?.sk} -{' '}
              <span className='ice_price'>{categories[39]?.price} </span>
            </span>
          </div>
          <div className='ice_price_row'>
            <img src={categories[40]?.image} alt='image' />
            <span>
              {categories[40]?.label?.sk} (
              <span className='ice_price'>{categories[40]?.price} </span>){' '}
              {categories[37]?.label?.sk}{' '}
              <span className='ice_price'>{categories[37]?.price}</span>
            </span>
          </div>
        </div>
        {/* GELATO INFO */}
        <div className='gelato_wrapper bottom_marg left_pad'>
          {gelato.map((item, index) => {
            let attributes = ''
            if (item?.attributes !== '') {
              attributes = '(' + item?.attributes + ')'
            }
            return (
              <div className='item'>
                {item?.label} <span>{attributes}</span>
              </div>
            )
          })}
        </div>
        {/* GELATO WRAPPER */}
        <div className='headline bottom_marg'>
          <div className='headline_icon'>
            <img src={categories[23]?.image} alt='image' />
          </div>
          <div className='headline_text'>
            <div className='headline_text'>
              {categories[23]?.label?.sk}
              <img src={veganIcon} alt='vegan' />
            </div>
          </div>
          <div className='headline_price'>{categories[23]?.price}</div>
        </div>
        {/* HEADLINE */}
        <div className='lemonades_wrapper left_pad'>
          {/* <div className='lemonades_category'> */}
          {/* <div className='name'>Ovocné:</div>
            <div className='list'>
              <span>Najsamdlhší názov</span>
              <span>Citrónová</span>
              <span>Hovädzia</span>
              <span>Hrušková</span>
            </div> */}
          {/* </div> */}
          <div className='lemonades_category'>
            <div
              dangerouslySetInnerHTML={{
                __html: categories[23]?.description?.sk
              }}
            />
          </div>
        </div>{' '}
        {/* LEMONADES */}
      </div>
      {/* THIRD ============================================== */}
      <div className='third boxed'>
        <div className='box'>
          <div className='headline bottom_marg'>
            <div className='headline_icon'>
              <img src={categories[24]?.image} alt='image' />
            </div>
            <div className='headline_text'>
              <div className='headline_text'>
                {categories[24]?.label?.sk}
                <img src={veganIcon} alt='vegan' />
              </div>
            </div>
            <div className='headline_price'>{categories[24]?.price}</div>
          </div>
          {/* HEADLINE */}
          <div className='lemonades_wrapper left_pad bottom_marg_small'>
            <div className='lemonades_category'>
              <div
                dangerouslySetInnerHTML={{
                  __html: categories[24]?.description?.sk
                }}
              />
            </div>
          </div>
          {/* LEMONADES */}
          <div className='headline bottom_marg'>
            <div className='headline_icon'>
              <img src={categories[25]?.image} alt='image' />
            </div>
            <div className='headline_text'>
              <div className='headline_text'>{categories[25]?.label?.sk}</div>
            </div>
            <div className='headline_price'>{categories[25]?.price}</div>
          </div>
          {/* HEADLINE */}
          <div className='lemonades_wrapper left_pad bottom_marg_small'>
            <div className='lemonades_category'>
              <div
                dangerouslySetInnerHTML={{
                  __html: categories[25]?.description?.sk
                }}
              />
            </div>
          </div>
          {categories[41]?.price && (
            <>
              <div className='headline bottom_marg'>
                <div className='headline_icon'>
                  <img src={categories[41]?.image} alt='image' />
                </div>
                <div className='headline_text'>
                  <div className='headline_text'>
                    {categories[41]?.label?.sk}
                  </div>
                </div>
                <div className='headline_price'>{categories[41]?.price}</div>
              </div>
              {/* HEADLINE */}
              <div className='lemonades_wrapper left_pad bottom_marg_small'>
                <div className='lemonades_category'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: categories[41]?.description?.sk
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className='headline bottom_marg'>
            <div className='headline_icon'>
              <img src={categories[19]?.image} alt='image' />
            </div>
            <div className='headline_text'>{categories[19]?.label?.sk}</div>
          </div>
          {/* HEADLINE */}
          <div className='drinks_wrapper left_pad bottom_marg'>
            {drinks.map((item, index) => {
              return (
                <div className='item'>
                  <div className='name'>{item?.label}</div>
                  <div className='price'>{item?.price}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='big_logo' />
        {/* LOGO */}
      </div>
      {/* THIRD ============================================== */}
      <div className='third'>
        <div className='headline bottom_marg'>
          <div className='headline_icon'>
            <img src={categories[20]?.image} alt='image' />
          </div>
          <div className='headline_text'>{categories[20]?.label?.sk}</div>
        </div>{' '}
        {/* HEADLINE */}
        <div className='bottom_marg_small'>
          {coffee.map((item, index) => {
            const image = item?.image !== '' ? item?.image : ''
            return (
              <div className='coffee_wrap left_pad'>
                <div className='coffee_box'>
                  <div className='coffee_icon'>
                    {image && <img src={image} alt='image' />}
                  </div>
                  <div className='coffee_text'>
                    <div className='coffee_row'>
                      <div className='coffee_fix'>
                        <div className='coffee_name'>{item?.label}</div>
                        <div className='coffee_price'>{item?.price}</div>
                      </div>
                    </div>
                    <div className='coffee_row'>
                      <div className='coffee_fix'>
                        <div className='coffee_sub_name'>
                          {item?.description?.sk}
                        </div>
                        <div className='coffee_sub_price' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {/* COVFEFE WRAPPER */}
        <div className='headline bottom_marg'>
          <div className='headline_icon'>
            <img src={categories[21]?.image} alt='image' />
          </div>
          <div className='headline_text'>{categories[21]?.label?.sk}</div>
        </div>
        {/* HEADLINE */}
        <div className='drinks_wrapper left_pad bottom_marg'>
          {sweet.map((item, index) => {
            return (
              <div className='item'>
                <div className='name'>{item?.label}</div>
                <div className='price'>{item?.price}</div>
              </div>
            )
          })}
        </div>
        {/* DRINKS */}
        <div className='info_box left_pad bottom_marg small_text'>
          <b>Alergény:</b>
          1. Obilniny obsahujúce lepok a výrobky z nich, 2. Kôrovce a výrobky z
          nich, 3. Vajcia a výrobky z nich, 4. Ryby a výrobky z nich, 5. Arašidy
          a výrobky z nich, 6. Sójové zrná a výrobky z nich, 7. Mlieko a výrobky
          z neho, 8. Orechy, ktorými sú mandle, lieskové orechy, vlašské orechy,
          kešu, pekanové orechy, para orechy, pistácie, makadamové orechy a
          výrobky z nich, 9. Zeler a výrobky z neho, 10. Horčica a výrobky z
          nej, 11. Sezamové semená a výrobky z nich, 12. Oxid siričitý a
          siričitany v koncentráciách vyšších ako 10 mg/kg alebo 10 mg/l.,13.
          Vlčí bôb a výrobky z neho, 14. Mäkkýše a výrobky z nich
        </div>
        {/* GELATO INFO */}
        {/* <div className='meniny left_pad right'>
          Dnes má meniny <span>Pavol</span>
        </div> */}
      </div>
      {/* THIRD ============================================== */}
    </div>
  )
}
export default Template_1
