import { Helmet, HelmetProvider } from 'react-helmet-async'
import { generateRandomNumber } from '../../../helper/common'
/**
 *
 * @param props
 * @returns
 */
const VideoPlayerFromCustomSource = props => {
  const { moduleSettings, block } = props
  const { googleFontLink, parameters } = moduleSettings
  let videoSrc = parameters?.text_input?.mapValue?.fields?.value?.stringValue
  videoSrc = videoSrc.replaceAll(' ', '%20')
  const { css } = block
  let { width, height } = css
  width = width.replace('px', '')
  height = height.replace('px', '')
  const videoData = `<video autoplay playsinline loop width="${width}" height="${height}"><source src="${videoSrc}" type="video/mp4"></video>`
  return (
    <div>
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div
          key={generateRandomNumber(1, 100000000)}
          dangerouslySetInnerHTML={{ __html: videoData }}
        />
      </HelmetProvider>
    </div>
  )
}

export default VideoPlayerFromCustomSource
