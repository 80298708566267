import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/**
 *
 * @param props
 * @returns
 */
const googleCalendar = props => {
  const { moduleSettings } = props
  const { specialData } = moduleSettings
  const title = specialData?.title?.stringValue
  const color = specialData?.color?.stringValue
  const fontSize = specialData?.fontSize?.stringValue
  const googleFontLink = moduleSettings.googleFontLink
  const newSize = fontSize * 1.2
  const titleStyle = {
    color: color,
    fontSize: newSize + 'px',
    padding: '0 10px',
    fontWeight: 'bold'
  }
  // https://github.com/akiran/react-slick
  var GcSsettings = {
    autoplay: true,
    autoplaySpeed: Number(moduleSettings.duration),
    fade: true,
    infinite: true,
    arrows: false,
    dots: false
  }
  let key = 0
  const events = moduleSettings.events

  if (events) {
    const styles = moduleSettings?.htmlContent
    const allEvents = Object.values(events).map(event => (
      <div key={key + 1}>
        <div
          style={{ padding: '10px' }}
          dangerouslySetInnerHTML={{ __html: event }}
        />
      </div>
    ))
    return (
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        {title && <div style={titleStyle}> {title}</div>}
        <div dangerouslySetInnerHTML={{ __html: styles }} />
        <SliderModule settings={GcSsettings} content={allEvents} />
      </HelmetProvider>
    )
  }
  return false
}
export default googleCalendar
