import { removeActiveBlocks } from './removeActiveBlocks'
import { updateActiveBlocks } from './updateActiveBlocks'

/**
 *
 * @param {*} blocks
 * @returns
 */
export const updateBlocksInLocalStorage = blocks => {
  // set blocks into local storage
  localStorage.setItem('blocks', JSON.stringify(blocks))
  // remove activeblock
  removeActiveBlocks()
  updateActiveBlocks(blocks)
}
