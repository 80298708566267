import * as Sentry from '@sentry/react'
// axios
import axios from 'axios'

export const updateScreen = async (presentation, screenId) => {
  const url = process.env.REACT_APP_API_URL + `/screen/update-presentation`
  const result = await axios({
    method: 'PUT',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      presentation,
      screenId
    }
  })
    .then(res => {
      return res.data.result
    })
    .catch(err => {
      console.log(err)
      Sentry.captureException(err)
      return false
    })
  if (result === false) {
    return result
  }
}
