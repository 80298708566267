import {
  WiDaySunny,
  WiDaySunnyOvercast,
  WiRain,
  WiDayFog,
  WiDayCloudyHigh,
  WiCloud,
  WiSnow,
  WiThunderstorm,
  WiShowers,
  WiNightClear,
  WiNightCloudy,
  WiNightFog
} from 'weather-icons-react'

export const WeatherIcon = (code, size, color, dayPart = 'day') => {
  const icons =
    dayPart === 'day'
      ? getIconsWeatherApiDay(size, color)
      : getIconsWeatherApiNight(size, color)
  for (const [key, value] of Object.entries(icons)) {
    if (Number(key) === code) {
      return value
    }
  }
  return false
}

const getIconsWeatherApiDay = (size, color) => {
  return {
    1000: <WiDaySunny size={size} color={color} />,
    1003: <WiDayCloudyHigh size={size} color={color} />,
    1006: <WiCloud size={size} color={color} />,
    1009: <WiDaySunnyOvercast size={size} color={color} />,
    1030: <WiDayFog size={size} color={color} />,
    1063: <WiRain size={size} color={color} />,
    1066: <WiSnow size={size} color={color} />,
    1069: <WiSnow size={size} color={color} />,
    1072: <WiSnow size={size} color={color} />,
    1087: <WiThunderstorm size={size} color={color} />,
    1114: <WiSnow size={size} color={color} />,
    1117: <WiSnow size={size} color={color} />,
    1135: <WiDayFog size={size} color={color} />,
    1147: <WiDayFog size={size} color={color} />,
    1147: <WiRain size={size} color={color} />,
    1153: <WiRain size={size} color={color} />,
    1168: <WiRain size={size} color={color} />,
    1171: <WiRain size={size} color={color} />,
    1180: <WiRain size={size} color={color} />,
    1183: <WiRain size={size} color={color} />,
    1186: <WiRain size={size} color={color} />,
    1189: <WiRain size={size} color={color} />,
    1192: <WiRain size={size} color={color} />,
    1195: <WiRain size={size} color={color} />,
    1198: <WiRain size={size} color={color} />,
    1201: <WiRain size={size} color={color} />,
    1201: <WiRain size={size} color={color} />,
    1204: <WiSnow size={size} color={color} />,
    1207: <WiSnow size={size} color={color} />,
    1210: <WiSnow size={size} color={color} />,
    1213: <WiSnow size={size} color={color} />,
    1216: <WiSnow size={size} color={color} />,
    1219: <WiSnow size={size} color={color} />,
    1222: <WiSnow size={size} color={color} />,
    1225: <WiSnow size={size} color={color} />,
    1237: <WiSnow size={size} color={color} />,
    1243: <WiShowers size={size} color={color} />,
    1246: <WiShowers size={size} color={color} />,
    1249: <WiShowers size={size} color={color} />,
    1252: <WiShowers size={size} color={color} />,
    1255: <WiSnow size={size} color={color} />,
    1258: <WiSnow size={size} color={color} />,
    1261: <WiSnow size={size} color={color} />,
    1264: <WiSnow size={size} color={color} />,
    1273: <WiThunderstorm size={size} color={color} />,
    1276: <WiThunderstorm size={size} color={color} />,
    1279: <WiThunderstorm size={size} color={color} />,
    1282: <WiThunderstorm size={size} color={color} />
  }
}
const getIconsWeatherApiNight = (size, color) => {
  return {
    1000: <WiNightClear size={size} color={color} />,
    1003: <WiNightCloudy size={size} color={color} />,
    1006: <WiCloud size={size} color={color} />,
    1009: <WiDaySunnyOvercast size={size} color={color} />,
    1030: <WiNightFog size={size} color={color} />,
    1063: <WiRain size={size} color={color} />,
    1066: <WiSnow size={size} color={color} />,
    1069: <WiSnow size={size} color={color} />,
    1072: <WiSnow size={size} color={color} />,
    1087: <WiThunderstorm size={size} color={color} />,
    1114: <WiSnow size={size} color={color} />,
    1117: <WiSnow size={size} color={color} />,
    1135: <WiNightFog size={size} color={color} />,
    1147: <WiNightFog size={size} color={color} />,
    1147: <WiRain size={size} color={color} />,
    1153: <WiRain size={size} color={color} />,
    1168: <WiRain size={size} color={color} />,
    1171: <WiRain size={size} color={color} />,
    1180: <WiRain size={size} color={color} />,
    1183: <WiRain size={size} color={color} />,
    1186: <WiRain size={size} color={color} />,
    1189: <WiRain size={size} color={color} />,
    1192: <WiRain size={size} color={color} />,
    1195: <WiRain size={size} color={color} />,
    1198: <WiRain size={size} color={color} />,
    1201: <WiRain size={size} color={color} />,
    1201: <WiRain size={size} color={color} />,
    1204: <WiSnow size={size} color={color} />,
    1207: <WiSnow size={size} color={color} />,
    1210: <WiSnow size={size} color={color} />,
    1213: <WiSnow size={size} color={color} />,
    1216: <WiSnow size={size} color={color} />,
    1219: <WiSnow size={size} color={color} />,
    1222: <WiSnow size={size} color={color} />,
    1225: <WiSnow size={size} color={color} />,
    1237: <WiSnow size={size} color={color} />,
    1243: <WiShowers size={size} color={color} />,
    1246: <WiShowers size={size} color={color} />,
    1249: <WiShowers size={size} color={color} />,
    1252: <WiShowers size={size} color={color} />,
    1255: <WiSnow size={size} color={color} />,
    1258: <WiSnow size={size} color={color} />,
    1261: <WiSnow size={size} color={color} />,
    1264: <WiSnow size={size} color={color} />,
    1273: <WiThunderstorm size={size} color={color} />,
    1276: <WiThunderstorm size={size} color={color} />,
    1279: <WiThunderstorm size={size} color={color} />,
    1282: <WiThunderstorm size={size} color={color} />
  }
}
