import React from 'react'
import { generateRandomNumber } from '../../helper/common'

const VideoPlayer = props => {
  const { moduleSettings, block } = props
  const { videoUrl, muted } = moduleSettings
  const { css } = block
  const { width, height } = css
  let muteVideo = ''
  const videoSrc = process.env.REACT_APP_SYMFONY_HOST + '/' + videoUrl
  if (muted) {
    muteVideo = 'muted'
  }
  const videoData = `<video autoplay playsinline ${muteVideo} loop width=${width} height=${height}><source src="${videoSrc}" type="video/mp4"></video>`

  return (
    <div
      key={generateRandomNumber(1, 100000000)}
      dangerouslySetInnerHTML={{ __html: videoData }}
    />
  )
}

export default VideoPlayer
