import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormTextField from './form-field'
import FormSelectField from './form-select-field'
import Logo from '../Styles/img/fenix-logo.png'

export default () => {
  const optionsData = [
    { value: 'false', name: 'Volno' },
    { value: 'true', name: 'Obsazeno' }
  ]
  const days = [
    { time: 'today_time_1', price: 'today_price_1', status: 'today_status_1' },
    { time: 'today_time_2', price: 'today_price_2', status: 'today_status_2' },
    { time: 'today_time_3', price: 'today_price_3', status: 'today_status_3' },
    { time: 'today_time_4', price: 'today_price_4', status: 'today_status_4' }
  ]

  return (
    <>
      <Row className='justify-content-md-center'>
        <Col xs={12} sm={2} md={2}>
          <img src={Logo} />
        </Col>
      </Row>

      <h2>Dnes</h2>
      <Row className='align-items-center'>
        {days.map((e, key) => {
          let time,
            price,
            status = ''
          if (key === 0) {
            time = 'Čas'
            price = 'Cena'
            status = 'Stav'
          } else {
            time = ''
            price = ''
            status = ''
          }
          return (
            <>
              <FormTextField
                as={Col}
                md='4'
                controlId=''
                label={time}
                type='text'
                name={e.time}
              />
              <FormTextField
                as={Col}
                md='4'
                controlId=''
                label={price}
                type='text'
                name={e.price}
              />
              <FormSelectField
                as={Col}
                sm='4'
                controlId=''
                label={status}
                type='text'
                name={e.status}
              >
                {optionsData.map((e, key) => {
                  return (
                    <option key={key} value={e.value}>
                      {e.name}
                    </option>
                  )
                })}
              </FormSelectField>
            </>
          )
        })}
      </Row>
    </>
  )
}
