import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'

/**
 *
 * @param props
 * @returns
 */
const TextSlider = props => {
  const { moduleSettings, content } = props
  const googleFontLink = moduleSettings.googleFontLink
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: moduleSettings.fade,
    autoplaySpeed: Number(moduleSettings.autoplaySpeed)
  }
  // added left / right padding
  let cssStyle = Object.assign({}, moduleSettings.moduleCss)
  cssStyle.padding = '0 10px'
  let key = 0
  const divs = content.html.map(line => (
    <div key={(key = key + 1)}>
      <p style={cssStyle}>{line.stringValue}</p>
    </div>
  ))

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <SliderModule settings={settings} content={divs} />
    </HelmetProvider>
  )
}
export default TextSlider
