/**
 *
 * @param {*} type
 * @param {*} parameters
 * @returns
 */
export const createTittleStyle = (
  type,
  parameters,
  paddingLeft = '5%',
  paddingRight = '5%',
  paddingTop = '2%',
  paddingBottom = '2%'
) => {
  if (type === 'title1') {
    return {
      color: parameters?.title_1_color?.stringValue,
      fontSize: parameters?.title_1_font_size?.stringValue + 'px',
      textAlign: parameters?.title_1_alignment?.stringValue,
      fontWeight: 'bold',
      paddingLeft: paddingLeft,
      paddingRight: paddingRight
    }
  } else if (type === 'title2') {
    return {
      color: parameters?.title_2_color?.stringValue,
      fontSize: parameters?.title_2_font_size?.stringValue + 'px',
      textAlign: parameters?.title_1_alignment?.stringValue,
      fontWeight: 'bold',
      paddingLeft: paddingLeft,
      paddingRight: paddingRight,
      paddingTop: paddingTop,
      paddingBottom: paddingBottom
    }
  } else if (type === 'text') {
    const size = Number(parameters?.text_font_size?.stringValue)
    return {
      color: parameters?.text_color?.stringValue,
      fontSize: size + 'px',
      textAlign: parameters?.text_alignment?.stringValue,
      lineHeight: size * 1.4 + 'px',
      paddingLeft: paddingLeft,
      paddingRight: paddingRight
    }
  }
}
