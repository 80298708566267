import axios from 'axios'
/**
 *
 * @param props
 * @returns
 */
const getTable = async futbalnetId => {
  const url =
    process.env.REACT_APP_API_URL +
    `/modules/futbalnet/getTable?id=${futbalnetId}`
  const result = await axios({
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (res.data.status === 'FAIL') {
        return false
      }
      return res.data.data
    })
    .catch(err => {
      console.log(err)
      return false
    })
  if (result) {
    let returnData = `<style>
    .mainClass {
      padding:5px
    }
    .table{
      padding:20px 0
    }
    .header {
      font-size:40px;
      color:#fff;
      text-align:left;
      margin-bottom:20px
    }
    .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      font-size:18px; 
      color:#000;
    }
    .column {
      flex: 5%;
      display: flex;
      align-items: center; 
      margin:6px 0;
      justify-content:center;
    }
    .headerTable {
      font-weight:bold;
      padding-top:20px
    }
    .bodyTable {
      padding-bottom:20px;
      background:#fff;
    }
    .team{
      flex:30%;
      justify-content:left;
    }
    .homeClub{
      color:#244892;
      font-weight:bold;
      background:#fdcd01!important;
    }
    .columns:nth-child(odd) { background:rgb(249, 249, 249)}
    .columns:nth-child(even) { background:#fff}
    </style>`
    returnData += `<div class="mainClass">`
    returnData += `<div class="header">${result.name}</div>`
    returnData += `<div class="headerTable">`
    returnData += `<div class="columns headerTable">`
    returnData += `<div class="column"></div>`
    returnData += `<div class="column team">Klub</div>`
    returnData += `<div class="column">Z</div>`
    returnData += `<div class="column">V</div>`
    returnData += `<div class="column">R</div>`
    returnData += `<div class="column">P</div>`
    returnData += `<div class="column">Body</div>`
    returnData += `<div class="column">Skóre</div>`
    returnData += `</div>`
    returnData += `</div>`
    returnData += `<div class="bodyTable">`
    let addClass = ''
    for (const club of result.data) {
      addClass = ''
      if (club.team === 'MFK Vrbové') {
        addClass = 'homeClub'
      }
      returnData += `<div class="columns ${addClass}">`
      returnData += `<div class="column order">${club.order}</div>`
      returnData += `<div class="column team">${club.team}</div>`
      returnData += `<div class="column matches">${club.matches}</div>`
      returnData += `<div class="column won">${club.won}</div>`
      returnData += `<div class="column draw">${club.draw}</div>`
      returnData += `<div class="column lost">${club.lost}</div>`
      returnData += `<div class="column points">${club.points}</div>`
      returnData += `<div class="column goals">${club.goals}</div>`
      returnData += `</div>`
    }
    returnData += '</div>'
    returnData += '</div>'
    return returnData
  }
}

export default getTable
