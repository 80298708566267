import React from 'react'

// Sentry
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
  state = { hasError: false }
  static getDerivedStateFromError (error) {
    return { hasError: true }
  }
  componentDidCatch (error, errorInfo) {
    // errorService.log({ error, errorInfo });
    Sentry.captureException(error)
  }
  render (error) {
    if (this.state.hasError) {
      return <h1>Something went wrong. Error was reported.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
