import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { getMainStyle } from './settings/mainStyle'

/**
 *
 * @param props
 * @returns
 */
const InfoTextLine = props => {
  const { moduleSettings, block } = props
  const blockHeight = Number(block.css.height.replace('px', ''))
  const { googleFontLink, parameters } = moduleSettings
  const fade = parameters?.scroll_effect?.stringValue === 'fade' ? true : false
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: fade,
    autoplaySpeed: Number(parameters.scroll_speed_number.stringValue) * 1000
  }
  // added left / right padding
  const fontColor = parameters?.font_color?.stringValue
  const fontSize = Number(parameters?.font_size?.stringValue)
  const text = parameters?.text_area_simple?.stringValue
  const lines = text.split('\n')

  const padding =
    parameters?.text_padding_left_right?.stringValue === 'on' ? true : false
  let textPadding = (blockHeight - fontSize) / 2 + 'px 0 '
  if (padding) {
    textPadding = (blockHeight - fontSize) / 2 + 'px 2% 0 2%'
  }

  const mainStyle = getMainStyle(parameters)
  mainStyle.padding = textPadding
  const articleTextStyle = {
    color: fontColor,
    fontSize: fontSize + 'px'
  }
  let divs
  let key = 0
  if (lines) {
    divs = lines.map(item => {
      return (
        <div key={(key = key + 1)}>
          {articleTextStyle && <div style={articleTextStyle}>{item}</div>}
        </div>
      )
    })
  }

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={mainStyle}>
        <SliderModule settings={settings} content={divs} />
      </div>
    </HelmetProvider>
  )
}
export default InfoTextLine
