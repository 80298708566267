import Clock from './modules/clock'
import FlashMessages from './modules/flashMessages'
import TextSlider from './modules/textSlider'
import SkolaObedy from './modules/skolaObedy'
import PdfReader from './modules/pdfReader'
import RssReader from './modules/rssReader'
import SimpleHtml from './modules/simpleHtml'
import GoogleCalendar from './modules/googleCalendar'
import AnalogClockModule from './modules/AnalogClockModule'
import WeatherWidgetIo from './modules/weatherWidgewtIo'
import YoutubeVidePlayer from './modules/youtubeVidePlayer'
import FenixSpa from './modules/fenixSpa'
import VideoPlayer from './modules/videoPlayer'
import Images from './modules/Images'
import Articles from './modules/Articles'
import Weather from './modules/weather'
import InfoTextLine from './modules/infoTextLine'
import SchoolCanteen from './modules/School/schoolCanteen'
import SchoolLessons from './modules/School/schoolLessons'
import MainMenu from './modules/Restaurant/MainMenu'
import RestaurantMenuList from './modules/Restaurant/RestaurantMenuList'
import VideoPlayerFromCustomSource from './modules/video/VideoPlayerFromCustomSource'
import postscribe from 'postscribe'
import { jumpSchedulerBa1 } from './modules/jumpFun/scheduler'
import ModuleDate from './modules/Date'

export const Module = props => {
  const { module, block, presentation } = props
  const moduleSettings = module?.parameters
  switch (module.code) {
    case 'clock':
    case 'clock-kiosk':
      return <Clock moduleSettings={moduleSettings} />
    case 'date':
      return <ModuleDate moduleSettings={moduleSettings} content={module} />
    case 'weather-widget':
      return <WeatherWidgetIo content={module} />
    case 'rss-reader':
      return (
        <div className='rssCss'>
          <RssReader moduleSettings={moduleSettings} content={module} />
        </div>
      )
    case 'image-gallery':
    case 'pdf-reader':
      return <PdfReader moduleSettings={moduleSettings} />
    case 'flash-messages':
      return <FlashMessages moduleSettings={moduleSettings} content={module} />
    case 'google-calendar':
    case 'google-calendar-kiosk':
      return <GoogleCalendar moduleSettings={moduleSettings} content={module} />
    case 'text-slider':
      return <TextSlider moduleSettings={moduleSettings} content={module} />
    case 'skola-obedy':
      return <SkolaObedy moduleSettings={moduleSettings} content={module} />
    case 'restaurant-menu-main':
      return <MainMenu moduleSettings={moduleSettings} content={module} />
    case 'restaurant-menu-list':
      return (
        <RestaurantMenuList moduleSettings={moduleSettings} content={module} />
      )
    case 'analog-watches':
      return (
        <AnalogClockModule moduleSettings={moduleSettings} content={module} />
      )
    case 'youtube-video':
      return (
        <YoutubeVidePlayer
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'video-player':
      return (
        <VideoPlayer
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'images':
      return (
        <Images
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'articles':
      return (
        <Articles
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'weather':
      return (
        <Weather
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'info-text-line':
      return (
        <InfoTextLine
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'school-canteen':
      return (
        <SchoolCanteen
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'school-lessons':
      return (
        <SchoolLessons
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'brc-video-player':
      return (
        <VideoPlayerFromCustomSource
          moduleSettings={moduleSettings}
          content={module}
          block={block}
        />
      )
    case 'fenix-spa':
      return <FenixSpa moduleSettings={moduleSettings} content={module} />

    case 'jumparena-countdown-1':
    case 'jumparena-countdown-2':
    case 'jumparena-countdown-3':
      setInterval(() => {
        // let scheduller = module?.specialData?.jumparena_scheduller
        const pathName = window.location.pathname.split('/')
        const jumpBranch = pathName[2]
        let scheduller
        switch (jumpBranch) {
          case 'jump-ba1':
            scheduller = jumpSchedulerBa1()
            break

          default:
            break
        }
        // let url = 'https://fe.datavision-online.eu/presentation/' + jumpBranch
        let url = 'localhost:3000/presentation/' + jumpBranch
        let newDate = new Date()
        let min = newDate.getMinutes()
        // let sec = Math.round(newDate.getSeconds() / 10) * 10;
        let sec = newDate.getSeconds()
        if (sec === 60) {
          sec = 0
        }
        sec = sec.toString().padStart(2, '0')
        min = min.toString().padStart(2, '0')
        let actTime = min + ':' + sec
        if (scheduller) {
          scheduller.map(item => {
            if (actTime === item.time) {
              // ak je zhoda, nacitame prezentaciu
              let newUrl = url + '~jumparena~' + item.type + '~' + item.value
              window.location.replace(newUrl)
            }
          })
        }
      }, 1000)
      if (moduleSettings.videoUrl) {
        return (
          <div dangerouslySetInnerHTML={{ __html: moduleSettings.videoUrl }} />
        )
      } else {
        console.log('tusom')
        // postscribe('#main', moduleSettings.js)
        // return <div dangerouslySetInnerHTML={{ __html: content.html }} />
      }

    default:
      return <SimpleHtml moduleSettings={moduleSettings} content={module} />
  }
}
