import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { presentationData } from '../api/getPresentation'
import { updateBlocksInLocalStorage } from '../api/localStorage/updateBlocks'
import ReactFontFace from 'react-font-face'
import aeroportalFont from '../assets/fonts/Aeroportal/aeroportal.ttf'
import { Module } from '../components/module'
import { blockScheduler } from '../helper/block/blockScheduler'
import {
  documentOnSnapshot,
  generateRandomNumber,
  isObjectEmpty
} from '../helper/common'

export const Presentation = () => {
  const initialValue = {
    display: {
      backgroundImage: '',
      backgroundSize: '',
      height: '',
      left: '',
      marginTop: 0,
      pos: '',
      top: '',
      transform: '',
      width: ''
    }
  }
  const { presentationId } = useParams()
  const [display, setDisplay] = useState(initialValue?.display)
  const [presentationInfo, setPresentationInfo] = useState()
  const [blocks, setBlocks] = useState()

  const [blocksData, setBlocksData] = useState()
  // const [changeBlockModule, setChangeBlockModule] = useState(false)

  // useEffect(() => {
  //   if (changeBlockModule) {
  //     setChangeBlockModule(false)
  //   }
  // }, [changeBlockModule])
  useEffect(() => {
    const fetchPresentationData = async () => {
      try {
        const presentation = await presentationData(presentationId)
        setPresentationInfo(presentation)
        console.log('presentation::: ', presentation)
        if (
          !presentation.hasOwnProperty('data') ||
          !presentation.hasOwnProperty('documentId')
        ) {
          return false
        }
        const documentId = presentation.documentId
        await documentOnSnapshot('presentation', documentId)
        const data = presentation.data
        if (data) {
          const bg = data?.css?.backgroundImage
          if (data.css) {
            const newBg = bg?.replace('\r', '')
            if (newBg) {
              data.css.backgroundImage = newBg
            }
            setDisplay(data.css)
          }
          if (data?.blocks) {
            setBlocks(data.blocks)

            updateBlocksInLocalStorage(data.blocks)
            blockScheduler()
          }
        } else {
        }
      } catch (e) {
        Sentry.captureException(e)
        console.error(e)
      }
    }
    if (presentationId) {
      fetchPresentationData()
    }
  }, [presentationId])

  useEffect(() => {
    let allBlocksData = {}
    if (blocks) {
      const returnData = Object.keys(blocks).map(blockId => {
        const block = blocks[blockId]
        let module = {}
        let moduleId = ''
        if (Object.hasOwnProperty.call(block, 'modules')) {
          const modules = Object.entries(block?.modules)
          // loop modules to find firs module by order
          let order = 1000
          let firstModuleData
          for (const moduleData of modules) {
            if (order > moduleData[1].order) {
              order = moduleData[1].order
              firstModuleData = moduleData
            }
          }
          if (firstModuleData[1].code !== undefined) {
            module = firstModuleData[1]
          }
          moduleId = blockId + '_' + module.code
          return (
            <div
              key={moduleId}
              style={block.css}
              id={blockId}
              data-module-code={module.code}
              data-module-duration={module.duration}
            >
              <Module
                module={module}
                block={block}
                presentation={presentationInfo}
              />
            </div>
          )
        } else {
          return (
            <div
              key={moduleId}
              style={block.css}
              id={blockId}
              data-module-code={module.code}
            ></div>
          )
        }
      })
      setBlocksData(returnData)
    }
    if (!isObjectEmpty(allBlocksData)) {
      Promise.all(allBlocksData)
        .catch(err => {
          Sentry.captureException(err)
          console.log('Promise was rejected!', err)
        })
        .then(results => {
          let returnData = []
          returnData = results.map(item => {
            const moduleCode = item.moduleCode
            const moduleId = item.moduleId
            return (
              <div
                key={moduleId}
                style={item.css}
                id={moduleId}
                data-url={item.moduleUrl}
                data-module-code={moduleCode}
                data-block-module-id={item.blocklModuleId}
              >
                <Module
                  moduleCode={moduleCode}
                  content={item.html}
                  moduleInfo={item.moduleInfo}
                />
              </div>
            )
          })
          setBlocksData(returnData)
        })
    }
  }, [blocks])
  return (
    <div key={generateRandomNumber(1, 100000000)} style={display}>
      {blocksData}
    </div>
  )
}
