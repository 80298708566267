export const GetCanteenMenuByType = (
  type = 1,
  data,
  todayDate,
  tomorrowDate
) => {
  return type === '1'
    ? menuFirstType(data, todayDate, tomorrowDate)
    : menuSecondType(data, todayDate, tomorrowDate)
}

const menuFirstType = (data, todayDate, tomorrowDate) => {
  let menuData = []
  let menu1
  let menu2
    if (!data || Object.keys(data).length === 0) {
    return menuData
  }

  for (const key of Object.keys(data)) {
    if (key === todayDate || key === tomorrowDate) {
      const day = data[key]
      let menu = day?.mapValue?.fields[2]?.mapValue?.fields?.nazov?.stringValue
      menu = menu.replace(/\n/g, '<br />')
      if (key === todayDate) {
        menu1 = menu
      } else {
        menu2 = menu
      }
    }
  }
  menuData.push(menu1)
  menuData.push(menu2)
  return menuData
}

const menuSecondType = (html, todayDate, tomorrowDate) => {
  let menuData = []
    if (!html) {
    return menuData
  }
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const rows = doc.querySelectorAll('tr')
  let counter = 0
  for (const row of rows) {
    if (counter > 1) {
      const cols = row.querySelectorAll('td')
      const date = sanitizeDate(cols[0])
      if (date === todayDate || date === tomorrowDate) {
        const menu = cols[4].innerHTML
        menuData.push(cols[4].innerHTML)
      }
    }
    counter++
  }
  return menuData
}

const sanitizeDate = input => {
  let date = input.innerText
  if (date) {
    date = date
      .replace(/ /g, '')
      .replace(/Pondelok/g, '')
      .replace(/Utorok/g, '')
      .replace(/Streda/g, '')
      .replace(/Štvrtok/g, '')
      .replace(/Piatok/g, '')
    return date
  }
  return false
}
