import { Helmet, HelmetProvider } from 'react-helmet-async'
import { generateRandomNumber } from '../../helper/common'
import moment from 'moment'

/**
 *
 * @param {*} props
 * @returns
 */
const ModuleDate = props => {
  const { moduleSettings, content } = props
  const { parameters } = content
  const { specialData } = moduleSettings
  const { dateSettings } = specialData
  const language =
    parameters?.parameters?.language?.mapValue?.fields?.value?.stringValue
  const dayNameType =
    parameters?.parameters?.day_name_type?.mapValue?.fields?.value?.stringValue

  const separator = parameters?.parameters?.text_input?.mapValue?.fields?.value
    ?.stringValue
    ? parameters?.parameters?.text_input?.mapValue?.fields?.value?.stringValue
    : '.'

  const showYear =
    parameters?.parameters?.true_false?.mapValue?.fields?.value?.stringValue

  const monthType =
    parameters?.parameters?.module_month?.mapValue?.fields?.value?.stringValue

  let lang = language
  if (language === 'cz') {
    lang = 'cs'
  }
  let dayName
  let momentDayName
  let momentDate
  moment.locale(lang)
  switch (dayNameType) {
    case '1':
      momentDayName = moment().format('dddd')
      dayName =
        momentDayName.charAt(0).toUpperCase() + momentDayName.slice(1) + ', '
      break
    case '2':
      momentDayName = moment().format('dd')
      dayName =
        momentDayName.charAt(0).toUpperCase() + momentDayName.slice(1) + ', '
      break
    default:
      dayName = ''
  }
  switch (monthType) {
    // short name
    case '0':
      momentDate = moment().format(`D${separator}M`)
      if (showYear === 'on') {
        momentDate = moment().format(`D${separator}MMM.YYYY`)
      }
      break
    // full month name
    case '1':
      momentDate = moment().format(`D${separator}M`)
      if (showYear === 'on') {
        momentDate = moment().format(`D${separator}MMMM YYYY`)
      }
      break
    // number
    default:
      momentDate = moment().format(`D${separator}M${separator}`)
      if (showYear === 'on') {
        momentDate = moment().format(`D${separator}M${separator}YYYY`)
      }
  }

  let updatedHtml = content?.html.replace('${todayLabel}', dayName)
  updatedHtml = updatedHtml.replace('${newDate}', momentDate)

  const googleFontLink = moduleSettings.googleFontLink
  return (
    <div>
      <HelmetProvider>
        {googleFontLink && (
          <Helmet>
            <link href={googleFontLink} rel='stylesheet' />
          </Helmet>
        )}
        <div
          key={generateRandomNumber(1, 100000000)}
          dangerouslySetInnerHTML={{ __html: updatedHtml }}
        />
      </HelmetProvider>
    </div>
  )
}

export default ModuleDate
