// Sentry
import * as Sentry from '@sentry/react'
// axios
import axios from 'axios'
import { connectFirestoreEmulator } from 'firebase/firestore'
import { preprocessPresentationData } from '../helper/presentation'

export const presentationData = async presentationUrl => {
  const url =
    process.env.REACT_APP_API_URL +
    `/display/presentation?url=${presentationUrl}`
  const result = await axios({
    method: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (res.data.status === 'FAIL') {
        Sentry.captureException(res.data)
        return false
      }
      return res.data.result
    })
    .catch(err => {
      Sentry.captureException(err)
      console.log(err)
      return false
    })
  if (result === false) {
    return result
  }
  if (result !== undefined) {
    const documentId = result.id.integerValue
    const data = await preprocessPresentationData(result)
    return {
      documentId,
      data
    }
  }
}
