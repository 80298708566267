import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import iconFalse from '../../assets/modules/FenixSpa/false_icon.png'
import iconTrue from '../../assets/modules/FenixSpa/true_icon.png'
/**
 * returns formated clock
 * @param props
 * @returns
 */
const FenixSpa = props => {
  const { moduleSettings, content } = props
  const { data, googleFontLink } = moduleSettings

  const fenixSpa = {
    fontFamily: 'Open Sans',
    color: '#ffffff'
  }
  const dayName = {
    textTransform: 'uppercase',
    fontSize: '50px',
    textAlign: 'center',
    clear: 'both'
  }
  const line = {
    fontSize: '20px',
    color: '#ffffff',
    marginTop: '40px',
    marginBottom: '120px',
    paddingLeft: '35px'
  }
  const day = {
    marginBottom: '50px',
    clear: 'both',
    paddingTop: '15px'
  }
  const time = {
    float: 'left',
    width: '150px',
    paddingTop: '10px',
    paddingLeft: '10px'
  }
  const icon = { float: 'left', width: '120px', textAlign: 'center' }
  const price = {
    float: 'left',
    width: '120px',
    paddingTop: '10px',
    paddingLeft: '30px',
    textAlign: 'left'
  }

  const today = ['Dnes', 'Today']
  const tomorrow = ['Zítra', 'Tomorrow']
  const [counter, setCounter] = useState(0)
  const [todayName, setTodayName] = useState(today[0])
  const [tomorrowName, setTomorrowName] = useState(tomorrow[0])

  const parsedData = JSON.parse(data)

  useEffect(() => {
    const interval = setInterval(() => {
      setTodayName(today[counter])
      setTomorrowName(tomorrow[counter])
      setCounter(counter === 0 ? 1 : 0)
    }, 5000)

    return () => clearInterval(interval)
  }, [counter])
  const keys = [1, 2, 3, 4]
  const todayData = keys.map(key => (
    <div style={day}>
      <div style={time} key={'today_time_' + key}>
        {parsedData['today_time_' + key]}
      </div>
      <div style={icon} key={'today_status_' + key}>
        {parsedData['today_status_' + key] === 'true' ? (
          <img src={iconFalse} />
        ) : (
          <img src={iconTrue} />
        )}
      </div>
      <div style={price} key={'today_price_' + key}>
        {parsedData['today_price_' + key]},- Kč
      </div>
    </div>
  ))
  const tomorrowData = keys.map(key => (
    <div style={day}>
      <div style={time} key={'tomorrow_time_' + key}>
        {parsedData['tomorrow_time_' + key]}
      </div>
      <div style={icon} key={'tomorrow_status_' + key}>
        {parsedData['tomorrow_status_' + key] === 'true' ? (
          <img src={iconFalse} />
        ) : (
          <img src={iconTrue} />
        )}
      </div>
      <div style={price} key={'tomorrow_price_' + key}>
        {parsedData['tomorrow_price_' + key]},- Kč
      </div>
    </div>
  ))
  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={fenixSpa}>
        <div style={dayName}>{todayName}</div>
        <div style={line}>{todayData}</div>
        <div style={dayName}>{tomorrowName}</div>
        <div style={line}>{tomorrowData}</div>
      </div>
    </HelmetProvider>
  )
}

export default FenixSpa
