import SliderModule from './slider'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { parseJsonData, prepareImage } from '../../helper/common'
import { createTittleStyle } from '../../helper/module/styleMaker'
import { getMainStyle } from './settings/mainStyle'

/**
 *
 * @param props
 * @returns
 */
const Articles = props => {
  const { moduleSettings, block } = props
  const blockWidth = Number(block.css.width.replace('px', ''))
  const { googleFontLink, parameters } = moduleSettings
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    autoplaySpeed: parameters.scroll_speed_number.stringValue
      ? Number(parameters.scroll_speed_number.stringValue) * 1000
      : 2000
  }
  // added left / right padding
  let cssStyle = Object.assign({}, moduleSettings.moduleCss)
  cssStyle.padding = '0'
  let key = 0
  const jsonData = parameters?.json_data?.stringValue
  const articles = jsonData ? parseJsonData(jsonData) : false
  let divs
  const hrWidth = (blockWidth / 100) * 20
  const hrMarginLeft = blockWidth / 2 - hrWidth / 2
  const tittle1Style = createTittleStyle('title1', parameters)
  const title2Style = createTittleStyle('title2', parameters)
  const articleTextStyle = createTittleStyle('text', parameters)
  const separatorStyle = {
    width: hrWidth + 'px',
    margin: '20px 0 20px ' + hrMarginLeft + 'px',
    height: '5px',
    border: 'none',
    background: parameters?.title_2_color?.stringValue,
    textAlign: parameters?.title_1_alignment?.stringValue
  }
  const imageStyle = {
    display: 'block',
    width: '50%',
    margin: '0 auto 3% auto'
  }

  const mainStyle = getMainStyle(parameters)

  if (articles) {
    for (const article in articles) {
      divs = articles[article].map(item => {
        let imageSrc
        if (Object.hasOwnProperty.call(item, 'image')) {
          imageSrc = prepareImage(item.image)
        }
        const title1 = item.title1
        const title2 = item.title2
        let text = item.text
        text = text.replace(/\n/g, '<br />')
        return (
          <div key={(key = key + 1)}>
            {imageSrc && (
              <img style={imageStyle} src={imageSrc} alt={'datavision'} />
            )}

            {tittle1Style && title1 && <div style={tittle1Style}>{title1}</div>}
            {title2Style && title2 && <div style={title2Style}>{title2}</div>}
            {separatorStyle && <div style={separatorStyle}></div>}
            {articleTextStyle && text && (
              <div
                style={articleTextStyle}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>
        )
      })
    }
  }

  return (
    <HelmetProvider>
      {googleFontLink && (
        <Helmet>
          <link href={googleFontLink} rel='stylesheet' />
        </Helmet>
      )}
      <div style={mainStyle}>
        <SliderModule settings={settings} content={divs} />
      </div>
    </HelmetProvider>
  )
}
export default Articles
