import React from 'react'
import { Helmet } from 'react-helmet'

const WeatherWidgetIo = props => {
  const { content } = props
  return (
    <>
      <Helmet>
        <script
          id='weatherwidget-io-js'
          src='https://weatherwidget.io/js/widget.min.js'
        ></script>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </>
  )
}

export default WeatherWidgetIo
