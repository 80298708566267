import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormTextField from './form-field'
import FormSelectField from './form-select-field'

export default () => {
  const optionsData = [
    { value: 'false', name: 'Volno' },
    { value: 'true', name: 'Obsazeno' }
  ]
  const days = [
    {
      time: 'tomorrow_time_1',
      price: 'tomorrow_price_1',
      status: 'tomorrow_status_1'
    },
    {
      time: 'tomorrow_time_2',
      price: 'tomorrow_price_2',
      status: 'tomorrow_status_2'
    },
    {
      time: 'tomorrow_time_3',
      price: 'tomorrow_price_3',
      status: 'tomorrow_status_3'
    },
    {
      time: 'tomorrow_time_4',
      price: 'tomorrow_price_4',
      status: 'tomorrow_status_4'
    }
  ]

  return (
    <>
      <h2>Zítra</h2>
      <Row className='align-items-center'>
        {days.map((e, key) => {
          let time,
            price,
            status = ''
          if (key === 0) {
            time = 'Čas'
            price = 'Cena'
            status = 'Stav'
          } else {
            time = ''
            price = ''
            status = ''
          }
          return (
            <>
              <FormTextField
                as={Col}
                md='4'
                controlId=''
                label={time}
                type='text'
                name={e.time}
              />
              <FormTextField
                as={Col}
                md='4'
                controlId=''
                label={price}
                type='text'
                name={e.price}
              />
              <FormSelectField
                as={Col}
                sm='4'
                controlId=''
                label={status}
                type='text'
                name={e.status}
              >
                {optionsData.map((e, key) => {
                  return (
                    <option key={key} value={e.value}>
                      {e.name}
                    </option>
                  )
                })}
              </FormSelectField>
            </>
          )
        })}
      </Row>
    </>
  )
}
